import Typed from "typed.js";
import { useEffect, useRef } from "react";

const TypedText = () => {
  // Create Ref element.
  const el: any = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["awesome", "stunning"], // Strings to displaY
      startDelay: 300,
      typeSpeed: 300,
      backSpeed: 200,
      backDelay: 200,
      smartBackspace: true,
      loop: true,
      loopCount: 2,
      showCursor: true,
      cursorChar: "|",
    });

    // Destroying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <h2 className="text-white text-4xl tracking-wide font-bold font-vollkorn md:text-7xl">
        Hi, We are building something{" "}
        <span
          ref={el}
          className="text-yellow-300 text-4xl tracking-wide font-bold font-vollkorn md:text-7xl"
        ></span>
      </h2>
    </div>
  );
};

export default TypedText;
