import React from "react";
import TypedText from "../components/TypedText";

const ComingSoon = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-500 via-violet-500 to-purple-500 h-screen w-screen">
      <div className="mx-auto h-screen flex flex-col items-center justify-center text-center p-6 gap-6 md:gap-12 md:w-4/6">
        <div className="w-52 md:w-80">
          <img src="assets/images/logo-wht.png" alt="logo" />
        </div>
        <div>
          <TypedText />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
