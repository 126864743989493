import React from "react";
import ComingSoon from "./pages/ComingSoon";

function App() {
  return (
    <div className="App">
      <header className="bg-red-500">
        <ComingSoon />
      </header>
    </div>
  );
}

export default App;
